import React, { useContext, useEffect, useState } from "react";
import { add } from "../../../components/assets";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Context } from "../../../context/Store";

import styled from "styled-components";
import Loader from "../../../components/loader/Loader";
import NodataFound from "../../includes/nodata/NodataFound";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import api from "../../../api";
import Swal from "sweetalert2";
import { FormControl, MenuItem, Select } from "@mui/material";
import ReactPaginate from "react-paginate";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { getDDMMYY, getYYMMDD } from "../../../utils/functions/function";

function Insurance() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("id");
  const [sortBy, setSortBy] = useState("");

  const [getData, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [render, setRender] = useState(true);
  const [active, setActive] = useState("");
  const [getInsurnceID, setInsuranceID] = useState("");
  const [societies, setSocieties] = useState([]);
  const [selectedSociety, setSelectedSociety] = useState(null);
  const [pagination, setPagination] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEnddate] = useState("");
  const [isPrintLoading, setIsPrintLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage + 1);
  }

  const navigate = useNavigate();
  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);

  //get accesstoken from store
  const token = user_data.access_token;
  const role = user_data.roles;

  useEffect(() => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_menu: "Insurance",
      },
    });
  }, []);

  //fInsurance listing
  useEffect(() => {
    setIsLoading(true);
    let url = "activities/list-plant-insurance/";
    if (active === "True") {
      url = `activities/list-plant-insurance/?insurance_type=True`;
    }
    api
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          society: selectedSociety,
          page: currentPage,
          start_date: getYYMMDD(startDate),
          end_date: getYYMMDD(endDate),
          sort_by: sortBy,
        },
      })
      .then((res) => {
        if (res.app_data.StatusCode === 6000) {
          setData(res.app_data.data);
          setIsDisabled(false)
          setIsLoading(false);
          setPagination(res.app_data.pagination_data);
        } else {
          setIsLoading(false);
          setIsDisabled(true)
          setData([]);
          setPagination(null);
        }
      }).catch(err => {
        setIsDisabled(true)
      })
  }, [active, render, selectedSociety, currentPage, startDate, endDate, sortBy]);

  const confirmDelete = (e, item) => {
    setRender(true);
    Swal.fire({
      title: "Are you sure to proceed?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        handleDelete(e, item);
      }
    });
  };
  const confirmClaim = (e, item) => {
    setRender(true);
    Swal.fire({
      title: "Are you sure to proceed?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then((result) => {
      console.log(result);
      if (result.isConfirmed) {
        handleClaim(e, item);
      }
    });
  };
  const confirmCancel = (e, item) => {
    setRender(true);
    Swal.fire({
      title: "Are you sure to cancel Insurance?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        cancelInsurance(e, item);
      }
    });
  };
  const cancelInsurance = (e, item) => {
    setRender(true);
    e.preventDefault();

    api
      .post(`activities/cancel-plant-insurance/${item.id}/`, {})
      .then((res) => {
        if (res.app_data.StatusCode == 6000) {
          setRender(false);
        } else {
        }
      });
  };
  const handleDelete = (e, item) => {
    e.preventDefault();

    api
      .post(`activities/delete-plant-insurance/${item.id}/`, {})
      .then((res) => {
        if (res.app_data.StatusCode == 6000) {
          setRender(false);
        } else {
        }
      });
  };
  const handleClaim = (e, item) => {
    e.preventDefault();

    api.post(`activities/insurance-claim/${item.id}/`, {}).then((res) => {
      if (res.app_data.StatusCode == 6000) {
        setRender(false);
      } else {
      }
    });
  };

  useEffect(() => {
    api
      .get(`accounts/society-names/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.app_data.StatusCode === 6000) {
          setSocieties(res.app_data.data);
        } else {
          setSocieties([]);
        }
      });
  }, []);

  const customStyles = {
    width: "100px",
    height: "30px",
    borderRadius: "8px",
    // Add any other custom styles here if needed
  };

  const CustomInput = ({ value, onClick }) => (
    <input
      type="text"
      value={value}
      onClick={onClick}
      style={{ width: "100px", height: "30px" }}
    />
  );

  const handleDownload = async () => {
    setIsPrintLoading(true);
    try {
      let endPoint = 'list-insurance/'

      if (role === "Plant") {
        endPoint = "list-plant-insurance/"
      }
      let apiUrl = `https://api.jeevamilk.com/api/v1/activities/${endPoint}`;

      // Construct query parameters
      const params = new URLSearchParams();
      if (startDate) params.append("start_date", getYYMMDD(startDate));
      if (endDate) params.append("end_date", getYYMMDD(endDate));
      if (selectedSociety) params.append("society", selectedSociety);
      params.append("download", "is_download");

      if (params.toString()) {
        apiUrl += `?${params.toString()}`;
      }

      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.status === 404) {
        alert("No data found!");
      }

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Get the HTML content
      const htmlContent = await response.text();

      // Open in new window for native PDF conversion
      const printWindow = window.open("", "_blank");
      printWindow.document.write(`
                      <html>
                          <head>
                              <style>
                                  @media print {
                                      @page {
                                          size: A4 landscape;
                                          margin: 10mm;
                                      }
                                  }
                              </style>
                              <script>
                                  window.onload = function() {
                                      // Delay print to ensure full page load
                                      setTimeout(() => {
                                          window.print();
                                      }, 500);
                                  };
                              </script>
                          </head>
                          <body>${htmlContent}</body>
                      </html>
                  `);
      printWindow.document.close();
      setIsPrintLoading(false);
    } catch (error) {
      console.error("PDF Generation Error:", error);
      setIsPrintLoading(false);
    }
  };
  const startIndex = (currentPage - 1) * 8;

  return (


    // Default sorting

    <Container>
      <Top>
        <h3>Insurance</h3>
        <div className="right">
          {/* Sorting Dropdown */}
          <div style={{ width: "200px", marginRight: "10px" }}>
            <FormControl fullWidth>
              <Select
                labelId="sort-by"
                id="sort-by"
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value)}
                displayEmpty
              > <MenuItem value="">Sort By</MenuItem>
                <MenuItem value="policy_number">Policy Number</MenuItem>
                <MenuItem value="date_of_insurance">Date of Insurance</MenuItem>

              </Select>
            </FormControl>
          </div>

          <div style={{ width: "180px" }}>
            <FormControl fullWidth>
              <Select
                labelId="society"
                id="society"
                value={selectedSociety || ""}
                onChange={(e) => setSelectedSociety(e.target.value)}
                displayEmpty
              >
                <MenuItem value="">All Society</MenuItem>
                {societies?.map((society, i) => (
                  <MenuItem key={i} value={society?.id}>
                    {society?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <DateContainer className="dates">
            <ButContainer>From</ButContainer>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              required
              dateFormat="dd/MM/yyyy"
              customInput={<CustomInput />}
            />
            <ButContainer>To</ButContainer>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEnddate(date)}
              required
              minDate={startDate}
              dateFormat="dd/MM/yyyy"
              customInput={<CustomInput />}
            />
            <FilterButton
              onClick={() => handleDownload()}
              disabled={isDisabled}
              style={{
                cursor: isDisabled ? "not-allowed" : "pointer",
                backgroundColor: isDisabled ? "grey" : "#fff",
              }}
            >
              {isPrintLoading ? "Loading..." : "Print"}
            </FilterButton>
          </DateContainer>

          <Button onClick={() => navigate("/add-insurance")}>
            <img src={add} alt="Add Insurance" />
            Add Insurance
          </Button>
        </div>
      </Top>

      <Bottom className="scroll">
        <TableHead>
          <TableItem className="bold">Sl No.</TableItem>
          <TableItem className="bold">Insurance ID</TableItem>
          <TableItem className="bold">Policy No</TableItem>
          <TableItem className="bold">Farmer name</TableItem>
          <TableItem className="bold">Tag No</TableItem>
          <TableItem className="bold">Society Name</TableItem>
          <TableItem className="bold">Date of Insurance</TableItem>
          <TableItem className="bold">Action</TableItem>
          <TableItem className=""></TableItem>
          <TableItem className=""></TableItem>
        </TableHead>
        <CoverContainer>
          {isLoading ? (
            <Loader />
          ) : getData.length > 0 ? (
            getData
              .sort((a, b) => {
                if (sortBy === "policy_number") {
                  return (a.policy_number || "").localeCompare(b.policy_number || "");
                } else {
                  return new Date(a.date_of_insurance || 0) - new Date(b.date_of_insurance || 0);
                }
              })
              .map((item, index) => (
                <TableContent key={index} onClick={() => setInsuranceID(item.id)}>
                  <TableItem>{startIndex + index + 1}</TableItem>
                  <TableItem>{item.code || "--"}</TableItem>
                  <TableItem>{item.policy_number || "--"}</TableItem>
                  <TableItem>{item.farmer_name || "--"}</TableItem>
                  <TableItem>{item.tag_no || "--"}</TableItem>
                  <TableItem>{item.society_name || "--"}</TableItem>
                  <TableItem>
                    {item.date_of_insurance ? getDDMMYY(item.date_of_insurance) : "DD-MM-YYYY"}
                  </TableItem>
                  <TableItem>
                    <ViewIcon to={`/view-insurance/${item.id}`}>
                      <i className="ri-eye-line"></i>
                    </ViewIcon>
                    <EditIcon
                      onClick={() =>
                        navigate(`/edit-insurance/${item.id}`, {
                          state: { insurance_id: item.insuranceId },
                        })
                      }
                    >
                      <ModeEditOutlineOutlinedIcon />
                    </EditIcon>
                    <DeleteIcon onClick={(e) => confirmDelete(e, item)}>
                      <i className="ri-delete-bin-6-line"></i>
                    </DeleteIcon>
                  </TableItem><TableItem className="cancel">
                    <button onClick={(e) => confirmCancel(e, item)}>
                      Cancel
                    </button>
                  </TableItem>
                  <TableItem className={item?.claim_approved_date ? "" : "claim"}>
                    {item?.claim_approved_date ? (
                      <span>{item?.claim_approved_date}</span>
                    ) : (
                      <button onClick={(e) => confirmClaim(e, item)}>
                        claim
                      </button>
                    )}
                  </TableItem>

                </TableContent>
              ))
          ) : (
            <NodataFound />
          )}
        </CoverContainer>
      </Bottom>
      {pagination && pagination?.total_pages > 1 && (
        <PaginationContainer>
          <PaginationText>
            Showing Milk Report : {pagination?.first_item} -{" "}
            {pagination?.last_item} of {pagination?.total_items} Reports
          </PaginationText>
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={pagination?.total_pages}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
          />
        </PaginationContainer>
      )}
    </Container>
  );
}

export default Insurance;


const DateContainer = styled.div`
    padding: 15px;
    /* background: #fff; */
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
`;

const ButContainer = styled.div`
	padding: 5px 8px;
	background: #2382d4;
	border-radius: 8px;
	color: #fff;
	font-size: 14px;
	font-weight: 400;
`;

const PaginationText = styled.p`
	color: #898989;
	font-size: 14px;
	@media all and (max-width: 890px) {
		margin-bottom: 10px;
	}
`;


const PaginationContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 0 15px;
	@media all and (max-width: 1380px) {
		padding: 15px 0px;
		margin-right: 40px;
	}
	@media all and (max-width: 1280px) {
		margin-right: 20px;
		padding: 20px 0px;
	}
	@media all and (max-width: 980px) {
		margin-right: 10px;
	}
	@media all and (max-width: 890px) {
		flex-direction: column;
	}
    
`;


const Container = styled.div`
  margin-top: 20px;
  padding: 0px 15px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const Top = styled.div`
  margin: 0px;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .right {
    display: flex;
    align-items: center;
    gap: 18px;
  }
`;

const Button = styled.div`
  width: 175px;
  height: 40px;
  background: #fff;
  color: #2382d4;
  align-items: center;
  display: flex;
  justify-content: center;
  border: 1px solid #2382d4;
  border-radius: 4px;
  font-size: 14px;
  padding: 6px 16px;
  cursor: pointer;

  &.background {
    background-color: #2382d4;
    color: #fff;
    margin-left: 15px;
  }

  img {
    margin-right: 10px;
  }
`;

const Tabs = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
`;

const Tab = styled(Link)`
  border-top: 1px solid #dfe8ed;
  text-decoration: none;
  border-left: 1px solid #dfe8ed;
  border-right: 1px solid #dfe8ed;
  border-bottom: 1px solid #dfe8ed;
  padding: 14px 20px;
  cursor: pointer;
  margin-right: 30px;
  color: #797d82;
  border-radius: 5px 5px 0 0;

  &.active {
    color: #2382d4;
    background-color: #e0f3ff;
    border-top: 1px solid #2382d4;
    border-left: 1px solid #2382d4;
    border-right: 1px solid #2382d4;
    border-bottom: 3px solid #2382d4;
  }
`;

const Bottom = styled.div`
  padding-top: 15px;
`;
const TableHead = styled.div`
  height: 70px;
  background: #f8f8f8;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const TableItems = styled.div`
  width: 10%;
  color: #000;
  font-size: 18px;
`;

const TableContainer = styled.div`
  height: 70px;
  background: #fff;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
`;

const CoverContainer = styled.div`
  max-height: calc(100vh - 250px);
  overflow: auto;
`;

const TableContent = styled.div`
  height: 70px;
  background: #fff;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const EditIcon = styled.div`
  color: #747474;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  margin-right: 10px;
  border-radius: 5px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DeleteIcon = styled.div`
  color: #747474;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  margin-right: 10px;
  border-radius: 5px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ViewIcon = styled(Link)`
  color: #747474;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  margin-right: 10px;
  border-radius: 5px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TableItem = styled.div`
  color: #747474;
  font-size: 15px;
  display: flex;
  font-family: "inter_regular";
  align-items: center;
  width: 12%;
  overflow: hidden;
  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
  &.bold {
    font-family: "inter_medium";
  }
  &.cancel {
    width: 120px !important;

    button {
      width: 100%;
      border: 1px solid #808080;
      border-radius: 6px;
      font-family: "inter_medium";
      color: #ff9e9e;
      cursor: pointer;
      background: #fff;
      padding: 6px 10px;
      /* Add margin for spacing */
      margin-right: 0 /* Adjust this value as needed */
    }
  }
  &.claim {
    width: 120px !important;
    margin-left: 10px;

    span {
      font-family: "";
      color: #017710;
      
    }
    button {
      display: block;
      width: 100%;
      border: 1px solid #808080;
      border-radius: 6px;
      font-family: "inter_medium";
      color: #017710;
      cursor: pointer;
      background: #fff;
      padding: 6px 10px;
    }
      
      
  }

`;

const FilterButton = styled.button`
	border: none;
	outline: none;
	border: 1px solid #2382d4;
	padding: 5px 12px;
	border-radius: 8px;
	position: relative;
`;